<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="theme">{{ theme.name }}</ion-label>
				</ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version large">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>
		<ion-content>

			<form class="container mb-4">
				<h1>Upraviť projekt</h1>

				<h2>Základné informácie</h2>
				<ion-row>
					<ion-col size="12" size-md="6" class="required px-1">
						<ion-label>Názov</ion-label>
						<ion-input type="text" placeholder="Zadajte názov projektu"></ion-input>
					</ion-col>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>Kategória</ion-label>
						<ion-select interface="popover" placeholder="Kategória">
							<ion-select-option aria-placeholder="Kategória">Kategória</ion-select-option>
							<ion-select-option aria-placeholder="Kategória">Kategória 2</ion-select-option>
							<ion-select-option aria-placeholder="Kategória">Kategória 3</ion-select-option>
						</ion-select>
					</ion-col>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>Tagy</ion-label>
						<ion-input type="text" placeholder="Zadajte tagy oddelené medzerou"></ion-input>
					</ion-col>
					<ion-col size="12" size-md="6" class="required px-1">
						<ion-label>Autor</ion-label>
						<ion-input type="text" placeholder="Meno autora" disabled></ion-input>
					</ion-col>
				</ion-row>
				<div class="required px-1">
					<ion-label>Popis</ion-label>
					<ion-textarea placeholder="Tento projekt..."></ion-textarea>
				</div>
				<div class="required mt-3 ml-1">
					<ion-label class="d-block">Titulná fotka projektu</ion-label>
					<ion-button class="mt-2 mb-2">Pridať</ion-button>
					<!-- <img class="d-block" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94e/346/60f94e3460772429568549.jpg" alt=""> -->
				</div>

				<h2>Linky</h2>
				<ion-row>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>URL na stiahnutie projektu</ion-label>
						<ion-input type="text" placeholder="https://..."></ion-input>
					</ion-col>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>URL projektu</ion-label>
						<ion-input type="text" placeholder="https://..."></ion-input>
					</ion-col>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>URL Facebook stránky</ion-label>
						<ion-input type="text" placeholder="https://..."></ion-input>
					</ion-col>
					<ion-col size="12" size-md="6" class="px-1">
						<ion-label>Embed URL</ion-label>
						<ion-input type="text" placeholder="https://..."></ion-input>
					</ion-col>
				</ion-row>
				<ion-button>Uložiť zmeny</ion-button>

				<p class="large ml-1 mt-1">*Po odoslaní zostane projekt skrytý až po schválenie administrátorom</p>

			</form>
		</ion-content>
	</ion-page>
</template>

<script>
import { arrowBackOutline, createOutline, personCircleOutline, logInOutline } from 'ionicons/icons'
import { mapGetters} 	from 'vuex'

export default {
	data() {
		return {
			arrowBackOutline,
			createOutline,
			personCircleOutline,
			logInOutline
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},
}
</script>

<style lang="sass" scoped>
ion-content
	--padding-start: 10px
	--padding-end: 10px

h2
	font-size: 25px

img
	max-width: 500px
	max-height: 500px

.required > ion-label:after
	color: red
	content: " *"

ion-button
	max-width: 100%
	--border-radius: 50px

ion-input, ion-select, ion-textarea
	height: 40px
	border: 1px solid gray
	padding: 10px !important
	border-radius: 10px
	margin: 10px 0 15px

ion-textarea
	height: auto !important
	padding: 0 0 0 10px !important

@media only screen and (max-width: 500px)
	img 
		max-width: 100%
</style>